import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,2,3,10,6,9];

export const dictionary = {
		"/(app)/(with-seo)": [~11,[2,3]],
		"/(app)/badges": [~20,[2,7]],
		"/(app)/blog": [21,[2]],
		"/(app)/blog/[slug]": [~22,[2]],
		"/(app)/(with-seo)/casino": [~13,[2,3]],
		"/(auth)/check": [~38,[10]],
		"/(auth)/confirm": [~39,[10]],
		"/(app)/(with-seo)/faq": [~14,[2,3,4]],
		"/(app)/(with-seo)/faq/[slug]": [~15,[2,3,4]],
		"/(app)/(with-seo)/favorites": [~16,[2,3]],
		"/(auth)/forgot": [40,[10]],
		"/(app)/(with-seo)/game/[slug]": [17,[2,3,5]],
		"/(app)/halloffame": [23,[2]],
		"/(auth)/login": [41,[10]],
		"/logout": [~45],
		"/(app)/loyalty": [~24,[2]],
		"/(app)/luckiest": [25,[2]],
		"/(app)/(with-seo)/pvp": [18,[2,3]],
		"/(app)/(with-seo)/recent": [19,[2,3,6]],
		"/(auth)/reset-password": [~42,[10]],
		"/(app)/rewards": [~26,[2]],
		"/(app)/rps": [27,[2,8]],
		"/(app)/rr": [~28,[2]],
		"/(app)/settings": [29,[2,9]],
		"/(app)/settings/ignored": [30,[2,9]],
		"/(app)/settings/preference": [31,[2,9]],
		"/(app)/settings/security": [32,[2,9]],
		"/(app)/settings/sessions": [33,[2,9]],
		"/(app)/settings/verification": [34,[2,9]],
		"/signup": [~46],
		"/(auth)/social": [43,[10]],
		"/(app)/terms": [~35,[2]],
		"/(app)/tournaments": [~36,[2]],
		"/(app)/tournaments/[slug]": [~37,[2]],
		"/(auth)/twofa": [44,[10]],
		"/(app)/(with-seo)/(games)/[slug=games]": [12,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';